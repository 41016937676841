import React, { useState } from 'react';
import './ImageTextSlider.css';

const ImageTextSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % 2);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + 2) % 2);
  };

  return (
    <div className="slider-container">
      <div className="slider-wrapper" style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
        <div className="slide">
          <img src="path/to/image1.jpg" alt="Image 1" className="slide-image" />
          <div className="slide-text">
            <h1>Text Section 1</h1>
            <p>This is the first text section that appears with the first image.</p>
          </div>
        </div>
        <div className="slide">
          <img src="path/to/image2.jpg" alt="Image 2" className="slide-image" />
          <div className="slide-text">
            <h1>Text Section 2</h1>
            <p>This is the second text section that appears with the second image.</p>
          </div>
        </div>
      </div>
      <button className="prev-btn" onClick={handlePrev}>❮</button>
      <button className="next-btn" onClick={handleNext}>❯</button>
    </div>
  );
};

export default ImageTextSlider;
