import React, { useState, useEffect } from 'react';
import Header from './Header';
import Footer from './footer';
import axios from 'axios'; // Import Axios for making HTTP requests

function Blog() {
  const [blogPosts, setBlogPosts] = useState([]);

  useEffect(() => {
    // Function to fetch blog posts from API
    async function fetchBlogPosts() {
      try {
        const response = await axios.get('https://server.tinsaesms.com/blog');
        setBlogPosts(response.data); // Assuming response.data is an array of blog posts
      } catch (error) {
        console.error('Error fetching blog posts:', error);
      }
    }

    fetchBlogPosts(); // Call the fetch function when component mounts
  }, []); // Empty dependency array ensures useEffect runs only once

  return (
    <>
      <Header />
     

      <div style={{ marginTop: "20px" }}></div>
      <div className="d-none d-lg-block" style={{ height: "80px" }}></div>
      <section className="banner-area inner-banner">
        <div>
          <div className="inner-banner inner-bg4">
            <div className="container">
              <div className="inner-title text-center">
                <h3>Our Latest Blogs</h3>
                <ul>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <i className="bx bxs-chevrons-right" />
                  </li>
                  <li>Blog</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="blog-area pt-100 pb-70">
        <div className="container">
          <div className="section-title text-center">
            <span>Blogs</span>
            <h2>Our Latest Posts</h2>
            <p>
              It is a long established fact that a reader will be
              distracted by the readable content of a page when looking at its layout.
            </p>
          </div>
          <div className="row pt-45">
            {/* Map over blogPosts array to render each blog post */}
            {blogPosts.map(post => (
              <div className="col-lg-4 col-md-6" key={post._id}>
                <div className="blog-card">
                  <div className="blog-img">
                    {/* Assuming each blog post object has an 'image' field */}
                    <a href={`/blogdetails/${post._id}`}>
                      <img src={`https://server.tinsaesms.com/${post.image}`} alt="Blog Images" />
                    </a>
                  </div>
                  <div className="blog-content">
                    <div className="blog-tag">
                      <a href={`/blogdetails/${post._id}`}><span>{post.author}</span></a>
                    </div>
                    <a href={`/blogdetails/${post._id}`}>
                      <h3>{post.title}</h3>
                    </a>
                    <a href={`/blogdetails/${post._id}`} className="read-btn">Read More</a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Blog;
