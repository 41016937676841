import React from 'react';
import Footer from './footer';
import Header from './Header';

function Dashboard() {
  return (
    <>
      <Header />
      <div style={{ marginTop: "20px" }}></div>
      <div className="d-none d-lg-block" style={{ height: "80px" }}></div>
      <div>
        <div className="inner-banner inner-bg2">
          <div className="container">
            <div className="inner-title text-center">
              <h3>Services</h3>
              <ul>
                <li>
                  <a href="index.html">Home</a>
                </li>
                <li>
                  <i className="bx bxs-chevrons-right" />
                </li>
                <li>Services</li>
              </ul>
            </div>
          </div>
        </div>
        <section className="service-area pt-100 pb-70">
          <div className="container">
            <div className="section-title text-center">
              <span>Post New Blog</span>
              <h2>This is your Dashboard &amp; admin from this page</h2>
              <p></p>
            </div>
            <div className="row pt-45">
              <div className="col-lg-4 col-sm-6">
                <div className="service-card">
                  <a href="/blogpost">
                    <img src="assets/img/service-icon/1.png" alt="Images" />
                  </a>
                  <a href="/blogpost">
                    <h3>Post New Blog</h3>
                  </a>
                  <p>Are you ready to create new blog selamawit</p>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="service-card">
                  <a href="/bloglist">
                    <img src="assets/img/service-icon/2.png" alt="Images" />
                  </a>
                  <a href="/bloglist">
                    <h3>Blog List</h3>
                  </a>
                  <p>Delete any blog from this page</p>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="service-card">
                  <a href="/home">
                    <img src="assets/img/service-icon/2.png" alt="Images" />
                  </a>
                  <a href="/home">
                    <h3>Home</h3>
                  </a>
                  <p>Go Back to your home</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
}

export default Dashboard;
