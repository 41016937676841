import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import './ContactUs.css'; // Ensure this CSS file is correctly linked

function ContactUs() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });

    const [showSuccess, setShowSuccess] = useState(false);
    const [showError, setShowError] = useState(false);

    const form = useRef();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        emailjs
            .sendForm('service_z4gxcls', 'template_sevr6cs', form.current, 'CT955h0-I4a8gfNoM')
            .then(
                () => {
                    setShowSuccess(true);
                    setFormData({
                        name: '',
                        email: '',
                        message: ''
                    });
                    setTimeout(() => setShowSuccess(false), 5000); // Hide message after 5 seconds
                },
                (error) => {
                    console.log('FAILED...', error.text);
                    setShowError(true);
                    setTimeout(() => setShowError(false), 5000); // Hide message after 5 seconds
                }
            );
    };

    return (
        <div className="contact-section">
            <div className="contact-container">
                <h2>Contact Us</h2>
                <p>We'd love to hear from you! Please fill out the form below and we'll get back to you as soon as possible.</p>
                <form ref={form} onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="name">Name<span>*</span></label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            placeholder="Enter your name"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Email<span>*</span></label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            placeholder="Enter your email"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="message">Message<span>*</span></label>
                        <textarea
                            id="message"
                            name="message"
                            value={formData.message}
                            onChange={handleChange}
                            placeholder="Enter your message"
                            rows="5"
                            required
                        ></textarea>
                    </div>
                    <button type="submit" className="submit-button">Send Message</button>
                </form>
                {showSuccess && <div className="popup success">Your message has been sent successfully!</div>}
                {showError && <div className="popup error">Failed to send your message. Please try again later.</div>}
            </div>
        </div>
    );
}

export default ContactUs;
