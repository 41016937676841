import React, { useState } from 'react';
import axios from 'axios';
import './blogpost.css';
import Header from './Header';
import Footer from './footer';


function BlogPostForm() {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState(''); // Fixed typo here
  const [author, setAuthor] = useState('');
  const [image, setImage] = useState(null);
  const [message, setMessage] = useState('');

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('title', title);
    formData.append('content', content); // Fixed typo here
    formData.append('author', author);
    formData.append('image', image);

    try {
      const response = await axios.post('https://server.tinsaesms.com/blog/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setMessage('Blog post submitted successfully!');
      // Reset form fields after submission
      setTitle('');
      setContent(''); // Fixed typo here
      setAuthor('');
      setImage(null);
    } catch (error) {
      setMessage('Failed to submit blog post. Please try again.');
    }
  };

  return (
    <div>
      <Header />
      <div style={{ marginTop: '20px' }}></div>
      <div className="d-none d-lg-block" style={{ height: '80px' }}></div>
      <div>
        <section className="banner-area inner-banner">
          <div>
            <div className="inner-banner inner-bg4">
              <div className="container">
                <div className="inner-title text-center">
                  <h3>Our Latest Blogs</h3>
                  <ul>
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>
                      <i className="bx bxs-chevrons-right" />
                    </li>
                    <li>Blog</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="blog-post-form">
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="title">Title:</label>
              <input
                type="text"
                id="title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="content">Content:</label> {/* Fixed typo here */}
              <textarea
                id="content"
                value={content}
                onChange={(e) => setContent(e.target.value)} // Fixed typo here
                required
              ></textarea>
            </div>
            <div className="form-group">
              <label htmlFor="author">Author:</label>
              <input
                type="text"
                id="author"
                value={author}
                onChange={(e) => setAuthor(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="image">Upload Image:</label>
              <input
                type="file"
                id="image"
                accept="image/*"
                onChange={handleImageChange}
                required
              />
            </div>
            <button type="submit">Submit</button>
          </form>
          {message && <p>{message}</p>}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default BlogPostForm;
