import React, { useState } from 'react';

function File() {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleAccordionClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <div className="faq-bg">
      <div className="faq-bg-into pt-100 pb-70">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-6">
              <div className="faq-content">
                <span>Why Choose Us</span>
                <h2>Providing Resource Connections, Educational Programs &amp; Advocacy Support for Single-Parent Families</h2>
                <p>
                Single-parent families often face unique challenges that require targeted support and resources to ensure their well-being and success. 
                </p>
              </div>
              <div className="faq-accordion">
                <ul className="accordion">
                  <li className="accordion-item">
                    <a
                      className={`accordion-title ${activeIndex === 0 ? 'active' : ''}`}
                      
                      onClick={() => handleAccordionClick(0)}
                    >
                      <i className="bx bx-chevron-down" />
                      Single Mothers Outreach SMO
                    </a>
                    <div className={`accordion-content ${activeIndex === 0 ? 'show' : ''}`}>
                      <p>
                       empower single parents and their children by providing hope, support, and resources so that families can become self-sustaining.
                      </p>
                    </div>
                  </li>
                  <li className="accordion-item">
                    <a
                      className={`accordion-title ${activeIndex === 1 ? 'active' : ''}`}
                      
                      onClick={() => handleAccordionClick(1)}
                    >
                      <i className="bx bx-chevron-down" />
                      To assist low-income single mother families
                    </a>
                    <div className={`accordion-content ${activeIndex === 1 ? 'show' : ''}`}>
                      <p>
                      To assist low-income single mother families as the mothers pursue a college education and financial independence.
                      </p>
                    </div>
                  </li>
                  <li className="accordion-item">
                    <a
                      className={`accordion-title ${activeIndex === 2 ? 'active' : ''}`}
                      
                      onClick={() => handleAccordionClick(2)}
                    >
                      <i className="bx bx-chevron-down" />
                      Educational workshops and seminars
                    </a>
                    <div className={`accordion-content ${activeIndex === 2 ? 'show' : ''}`}>
                      <p>
                      To provide resource connections, educational programs, and advocacy support for single parent families.
                      </p>
                    </div>
                  </li>
                  <li className="accordion-item">
                    <a
                      className={`accordion-title ${activeIndex === 3 ? 'active' : ''}`}
                      
                      onClick={() => handleAccordionClick(3)}
                    >
                      <i className="bx bx-chevron-down" />
                     We give tutorial for the children 
                    </a>
                    <div className={`accordion-content ${activeIndex === 3 ? 'show' : ''}`}>
                      <p>
                      These organizations provide various forms of assistance, ranging from financial aid and housing support to educational programs and counseling, helping single mothers achieve stability and self-sufficiency.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="faq-img-2">
                <img src="assets/img/faq/2.jpg" alt="FAQ Images" />
              </div>
              <div className="faq-content-text">
                <p>
                Single-parent families often face unique challenges that require targeted support and resources to ensure their well-being and success.  <a >See More</a>
                </p>
                <div className="row justify-content-center">
                  <div className="col-lg-4 col-sm-6 col-md-4">
                    <div className="single-counter">
                      <h3 className="color-black"><span className="counter">14</span></h3>
                      <p className="color-black font-size">Years Experience</p>
                    </div>
                  </div>
                  <div className="col-lg-4 col-sm-6 col-md-4">
                    <div className="single-counter">
                      <h3 className="color-black"><span className="counter">150</span>+</h3>
                      <p className="color-black font-size">Single Mom </p>
                    </div>
                  </div>
                  <div className="col-lg-4 col-sm-12 col-md-4">
                    <div className="single-counter">
                      <h3 className="color-black"><span className="counter">9</span>+</h3>
                      <p className="color-black font-size">Expert Members</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default File;
