import React, { useState, useEffect } from 'react';
import './headslider.css';

function Headslider() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const services = [
    {
      title: 'Mechanical Works',
      image: 'assets/img/inner-banner/ban-1.png',
      description: 'Ut enim ad minim veniam, quis nos trud exerci tation ullamco.',
      link: 'service-single.html',
    },
    {
      title: 'Power & Energy',
      image: 'assets/images/services/img-2.jpg',
      description: 'Ut enim ad minim veniam, quis nos trud exerci tation ullamco.',
      link: 'service-single.html',
    },
    {
      title: 'Petroleum Refinery',
      image: 'assets/images/services/img-3.jpg',
      description: 'Ut enim ad minim veniam, quis nos trud exerci tation ullamco.',
      link: 'service-single.html',
    },
    {
      title: 'Mechanical Works',
      image: 'assets/images/services/img-1.jpg',
      description: 'Ut enim ad minim veniam, quis nos trud exerci tation ullamco.',
      link: 'service-single.html',
    }
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % services.length);
    }, 3000); // Change slide every 3 seconds

    return () => clearInterval(interval);
  }, [services.length]);

  return (
    <section className="section-padding">
      <div className="container">
        <div className="row">
          <div className="col col-lg-3 col-md-4">
            <div className="section-title">
              <h2>Our services</h2>
            </div>
          </div>
          <div className="col col-lg-6 col-md-5">
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
          </div>
          <div className="col col-lg-3 col-md-3">
            <div className="all-service-link">
              <a href="services.html" className="theme-btn">All services</a>
            </div>
          </div>
        </div> {/* end row */}
        <div className="row">
          <div className="col col-xs-12">
            <div className="services-grids">
              {services.map((service, index) => (
                <div
                  key={index}
                  className={`grid ${index === currentIndex ? 'active' : ''}`}
                  style={{
                    transform: `translateX(${100 * (index - currentIndex)}%)`,
                    transition: 'transform 0.5s ease-in-out'
                  }}
                >
                  <div className="inner mk-bg-img">
                    <div className="details">
                      <div className="info">
                        <img src={service.image} alt={service.title} className="bg-image" />
                        <a href={service.link}>
                          <h3><i className="fi flaticon-construction" /> {service.title}</h3>
                        </a>
                        <p>{service.description}</p>
                        <a href={service.link} className="more">Get Details</a>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div> {/* end services-grids */}
          </div> {/* end col */}
        </div> {/* end row */}
      </div> {/* end container */}
    </section>
  );
}

export default Headslider;
