import React from 'react'
import Header from './Header'
import Banner from './banner'
import File from './file'
import Members from './members'
import Blog from './Blog'
import Footer from './footer'
import Abouthome from './Abouthome'
import VideoSlider from './video'
import HeroSlider from './Heroslider'
import EventComponent from './event'
function Home() {
  return (
    <div>
      <Header />
      
   
      <Banner />
      <File />
      
     
      <Abouthome/>
      <VideoSlider/>
      
  <HeroSlider/>

      
      <EventComponent/>
      <Members />
      <Footer/>
   
    </div>
  )
}

export default Home
