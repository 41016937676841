import React, { useEffect, useState } from 'react';
import './HeroSlider.css';

const images = [
  'assets/img/inner-banner/ban-1.png',
  'assets/img/inner-banner/ban-2.png',
  'assets/img/inner-banner/ban-3.png'
];

function HeroSlider() {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 2000); // Change slide every 3 seconds
    return () => clearInterval(interval);
  }, []);

  return (
    <div style={{ marginTop: "20px" }}>
 
    <div className="hero-slider">
      {images.map((image, index) => (
        <div
          key={index}
          className={`slide ${index === currentIndex ? 'active' : ''}`}
          style={{ backgroundImage: `url(${image})` }}
        >
          <div className="overlay">
         
          
          </div>
        </div>
      ))}
    </div>
    </div>
   
   
  );
}

export default HeroSlider;
