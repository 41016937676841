import React from 'react'
import Header from './Header'
import Footer from './footer'

function About() {
  return (
   
<div>
  <Header/>
  <div style={{ marginTop: "20px" }}></div>
      <div className="d-none d-lg-block" style={{ height: "80px" }}></div>
  <div className="inner-banner inner-bg1">
    <div className="container">
      <div className="inner-title text-center">
        <h3>About Us</h3>
        <ul>
          <li>
            <a href="/">Home</a>
          </li>
          <li>
            <i className="bx bxs-chevrons-right" />
          </li>
          <li>About</li>
        </ul>
      </div>
    </div>
  </div>
  <div className="about-area about-max pt-100 pb-70">
    <div className="container-fluid">
      <div className="row align-items-center justify-content-center">
        <div className="col-lg-5 col-xxl-6">
          <div className="about-img about-ml">
            <img src="assets/img/about/3.jpg" alt="About Images" />
          </div>
        </div>
        <div className="col-lg-7 col-xxl-6">
          <div className="about-content about-width">
            <span>About Us</span>
            <h2>Tinsae SMS encourages individuals to volunteer and join their team to actively support the organization's work.</h2>
            <p>
            To provide a range of critical services and resources to empower and uplift single mothers, with the goal of improving their overall well-being and quality of life.
            The organization recognizes the unique challenges that single mothers face in managing their household budgets and aims to provide the necessary financial support.
            Tinsae SMS provides a supportive community and resources to help single mothers navigate emotional challenges.
Services include counseling, support groups, and tools to promote self-care, resilience, and overall mental and emotional well-being.
            </p>
            <p>
            The organization relies on donations from the public to sustain and grow its programs, enabling them to assist more single mothers and their families in need.
Community contributions are essential for Tinsae SMS to continue making a difference in the lives of those they serve.
            </p>
            <div className="about-btn">
              <a href="about" className="default-btn active">Know More</a>
       
            </div>
          </div>

        </div>
      </div>
      <Footer/>
    </div>
  </div>
</div>
    

  )
}

export default About