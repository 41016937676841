import React from 'react'

function Footer() {
  return (
    
<footer id="footer" className="footer-area-bg">
  <div className="footer-area-top pt-100 pb-70">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-4 col-md-6">
          <div className="footer-single">
            <div className="footer-single-content">
              <a href="/">
                <img src="assets/img/footer-logo.png" alt="Logo" />
              </a>
              <p></p>
            </div>
            <div className="newsletter-area">
              <form className="newsletter-form" data-toggle="validator">
                <input type="email" className="form-control" placeholder="Email" name="EMAIL" required autoComplete="off" />
                <button className="subscribe-btn" type="submit">
                  Subscribe
                </button>
                <div id="validator-newsletter" className="form-result" />
              </form>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <div className="footer-list ml-50">
            <h3>Services</h3>
            <ul>
              <li>
                <i className="bx bxs-chevron-right" />
                <a href="">single mom</a>
              </li>
              <li>
                <i className="bx bxs-chevron-right" />
                <a href="">charity </a>
              </li>
              <li>
                <i className="bx bxs-chevron-right" />
                <a href="">consulting </a>
              </li>
              <li>
             
              </li>
              <li>
            
              </li>
              <li>
            
              </li>
            </ul>
          </div>
        </div>
        <div className="col-lg-2 col-md-6">
          <div className="footer-list">
            <h3>Quick Links</h3>
            <ul>
              <li>
                <i className="bx bxs-chevron-right" />
                <a href="/">Home</a>
              </li>
              <li>
                <i className="bx bxs-chevron-right" />
                <a href="service">Service</a>
              </li>
              <li>
                <i className="bx bxs-chevron-right" />
                <a href="about">About Us</a>
              </li>
              <li>
       
              </li>
              <li>
                <i className="bx bxs-chevron-right" />
                <a href="blog">Blog</a>
              </li>
              <li>
                <i className="bx bxs-chevron-right" />
                <a href="contact">Contact</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <div className="footer-list ml-20">
            <h3>Contact Us</h3>
            <ul>
              <li>
                <i className="bx bxs-chevron-right" />
                <a href="tel:+17205172306">+17205172306</a>
              </li>
              <li>
           
              </li>
              <li>
                
              </li>
              <li>
                
                
              </li>
              <li>
                <i className="bx bxs-chevron-right" />
                denver, USA
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="footer-bottom">
    <div className="container">
      <div className="row align-items-center justify-content-center">
        <div className="col-lg-12">
          <div className="bottom-text text-center">
            <p>© Hibir solution  <a href="https://hibirsolution.com/" target="_blank">And Digital market </a></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>

  )
}

export default Footer