import React from 'react'

function Banner() {
  return (
    
    <div>

    <div>
      <div style={{marginBottom: "0"}}>
  <div className="main-banner">
    <div className="container-fluid">
      <div className="row justify-content-center">
        <div className="col-lg-6">
          <div className="banner-text">
            <span>Tinsae Humanitarian Charity Organization</span>
            <h1>Each department plays a critical role </h1>
            <p>
            this organization focuses on assisting Ethiopian single mothers by forming educational alliances and advocating for their rights. The support extends to providing essential resources and creating a supportive community for single mothers and their children​ 
            </p>
            <div className="banner-btn">
              <a href="contact" className="default-btn">Contact Us</a>
              <a href="about" className="default-btn active">Know More</a>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="banner-img">
            <img src="assets/img/home1.png" alt="Banner Images" />
          </div>
        </div>
        <div className="banner-shape-1">
          <img src="assets/img/shape/home1.png" alt="Banner Shape" />
        </div>
      </div>
    </div>
    <div className="home-shape">
      <div className="shape1">
        <img src="/assets/img/shape/1.png" alt="shape" />
      </div>
      <div className="shape2">
        <img src="/assets/img/shape/2.png" alt="shape" />
      </div>
      <div className="shape3">
        <img src="/assets/img/shape/3.png" alt="shape" />
      </div>
      <div className="shape4">
        <img src="/assets/img/shape/4.png" alt="shape" />
      </div>
      <div className="shape5">
        <img src="/assets/img/shape/5.png" alt="shape" />
      </div>
      <div className="shape6">
        <img src="/assets/img/shape/6.png" alt="shape" />
      </div>
      <div className="shape7">
        <img src="/assets/img/shape/3.png" alt="shape" />
      </div>
    </div>
  </div>
  

          </div>
        </div>
        </div>

    
  )
}

export default Banner