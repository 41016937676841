import React, { useState, useEffect } from 'react';
import Header from './Header';
import './blogdetail.css';
import Footer from './footer';
import axios from 'axios';
import { useParams } from 'react-router-dom';

function Blogdetails() {
  const { id } = useParams();
  const [blogPost, setBlogPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchBlogPostDetails() {
      try {
        console.log('Fetching blog post with id:', id); // Debugging log
        const response = await axios.get(`https://server.tinsaesms.com/blog/${id}`);
        console.log('Blog post data:', response.data); // Debugging log
        setBlogPost(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching blog post details:', error); // Debugging log
        setError('Error fetching blog post details');
        setLoading(false);
      }
    }

    fetchBlogPostDetails();
  }, [id]);

  if (loading) return <div className="loading">Loading...</div>;
  if (error) return <div className="error-message">{error}</div>;

  if (!blogPost) {
    return <div className="error-message">No blog post found</div>;
  }

  return (
    <div>
      <Header />
      <div style={{ marginTop: "20px" }}></div>
      <div className="d-none d-lg-block" style={{ height: "80px" }}></div>

      <section className="banner-area inner-banner">
        <div className="inner-banner inner-bg5" style={{ padding: '20px', textAlign: 'center' }}>
          <div className="container">
            <div className="inner-title text-center">
              <h3 style={{ fontSize: '2em', marginBottom: '10px' }}>Blog Details</h3>
              <ul style={{ listStyleType: 'none', padding: 0 }}>
                <li>
                  <a href="/" style={{ color: '#007bff', textDecoration: 'none' }}>Home</a>
                </li>
                <li style={{ display: 'inline', margin: '0 10px' }}>
                  <i className="bx bxs-chevrons-right" />
                </li>
                <li style={{ display: 'inline' }}>Blog Details</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <div className="blog-dtls ptb-100" style={{ padding: '40px 15px' }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="blog-dtls-content">
                <div className="blog-dtls-img">
                  <img
                    src={`https://server.tinsaesms.com/${blogPost.image}`}
                    alt={blogPost.title || "Blog Post Image"}
                    style={{
                      width: '100%',
                      height: 'auto',
                      objectFit: 'cover',
                      borderRadius: '8px',
                      marginBottom: '20px',
                    }}
                  />
                </div>
                <div className="blog-text" style={{ textAlign: 'left', lineHeight: '1.6', color: '#333' }}>
                  <h2 style={{ fontSize: '2em', marginBottom: '20px' }}>{blogPost.title}</h2>
                  <p style={{ fontSize: '1em', marginBottom: '20px' }}>{blogPost.content}</p>
                  {blogPost.additionalContent && (
                    <div>
                      <h3 style={{ fontSize: '1.5em', marginBottom: '10px' }}>{blogPost.additionalContent.title}</h3>
                      <p style={{ fontSize: '1em', marginBottom: '20px' }}>{blogPost.additionalContent.text}</p>
                    </div>
                  )}
                </div>
                <div className="article-footer" style={{ marginTop: '30px' }}>
                  <div className="article-tags" style={{ marginBottom: '10px' }}>
                    <span><i className="bx bx-share-alt" /></span>
                    <a href="#share" style={{ color: '#007bff', textDecoration: 'none' }}>Share</a>
                  </div>
                  <div className="article-share">
                    <ul className="social" style={{ listStyleType: 'none', padding: 0, display: 'flex', gap: '10px' }}>
                      <li>
                        <a href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`} target="_blank" rel="noopener noreferrer">
                          <i className="bx bxl-facebook" />
                        </a>
                      </li>
                      <li>
                        <a href={`https://twitter.com/share?url=${window.location.href}`} target="_blank" rel="noopener noreferrer">
                          <i className="bx bxl-twitter" />
                        </a>
                      </li>
                      <li>
                        <a href={`https://www.linkedin.com/shareArticle?url=${window.location.href}`} target="_blank" rel="noopener noreferrer">
                          <i className="bx bxl-linkedin" />
                        </a>
                      </li>
                      <li>
                        <a href={`https://www.pinterest.com/pin/create/button/?url=${window.location.href}`} target="_blank" rel="noopener noreferrer">
                          <i className="bx bxl-pinterest-alt" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="post-navigation" style={{ marginTop: '30px' }}>
                  <div className="navigation-links">
                    {blogPost.prevPostId && (
                      <div className="nav-previous" style={{ marginBottom: '10px' }}>
                        <a href={`/blogdetails/${blogPost.prevPostId}`}>Prev Post</a>
                      </div>
                    )}
                    {blogPost.nextPostId && (
                      <div className="nav-next">
                        <a href={`/blogdetails/${blogPost.nextPostId}`}>Next Post</a>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Blogdetails;
