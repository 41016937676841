import React, { useState } from 'react';
import './header.css';

function Header() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <div className="navbar-area">
      <div className="mobile-nav">
        <a href="index" className="logo">
          <img src="/assets/img/logo.png" className="logo-one" alt="Logo" />
          <img src="/assets/img/sticky-logo.png" className="logo-two" alt="Logo" />
        </a>
        <button className="mobile-nav-toggle" onClick={toggleMobileMenu}>
          <span className="navbar-toggler-icon"></span>
        </button>
        {isMobileMenuOpen && (
          <div className="mobile-nav-menu">
            <ul>
              <li><a href="/">Home</a></li>
              <li><a href="/about">About</a></li>
              <li><a href="/Service">Services</a></li>
           
              <li><a href="/blog">Blog</a></li>
              <li><a href="/signin">Signin</a></li>
          
              
            
            </ul>
          </div>
        )}
      </div>
      <div className="main-nav">
        <div className="container">
          <nav className="navbar navbar-expand-md navbar-light">
            <a className="navbar-brand" href="index">
              <img src="/assets/img/logo.png" alt="Logo" />
            </a>
            <div className="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
              <ul className="navbar-nav m-auto">
                <li className="nav-item"><a href="/" className="nav-link">Home</a></li>
                <li className="nav-item"><a href="/about" className="nav-link">About</a></li>
                <li className="nav-item"><a href="/Service" className="nav-link">Services</a></li>
                <li className="nav-item"><a href="/blog" className="nav-link">Blog</a></li>
              

              </ul>
              <div className="menu-btn">
                <a href="/signin" className="seo-btn">Sign in</a>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
}

export default Header;
