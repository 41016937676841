import React, { useState } from 'react';
import './Mobheader.css';
function MobHeader() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <div className="mob-header">
      <div className="mobile-nav">
        <a href="index" className="logo">
          <img src="assets/img/logo.png" className="logo-one" alt="Logo" />
          <img src="assets/img/sticky-logo.png" className="logo-two" alt="Logo" />
        </a>
        <button className="mobile-nav-toggle" onClick={toggleMobileMenu}>
          <span className="navbar-toggler-icon"></span>
        </button>
      </div>

      {/* Mobile Navigation Menu */}
      {isMobileMenuOpen && (
        <div className="mobile-nav-menu">
          <ul>
            <li><a href="home">Home</a></li>
            <li><a href="about">About</a></li>
            <li>
              <a href="#">Services</a>
              <ul className="dropdown-menu">
                <li><a href="services">Services</a></li>
                <li><a href="service-details">Service Details</a></li>
              </ul>
            </li>
            <li>
              <a href="#">Blog</a>
              <ul className="dropdown-menu">
                <li><a href="blog">Blog</a></li>
                <li><a href="blog-details">Blog Details</a></li>
              </ul>
            </li>
            <li><a href="contact">Contact</a></li>
            <li><a href="portfolio" className="seo-btn">Donate</a></li>
          </ul>
        </div>
      )}
    </div>
  );
}

export default MobHeader;
