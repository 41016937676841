import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Header from './Header';
import Footer from './footer'; // Assuming 'Footer' component is imported correctly

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://server.tinsaesms.com/auth/login', { email, password });
      localStorage.setItem('token', response.data.token); // Store the token in localStorage
      navigate('/dashboard'); // Navigate to blogpost.js
    } catch (error) {
      setError('Invalid email or password');
    }
  };

  return (
    <div>
      <Header />
      <div style={{ marginTop: "20px" }}></div>
      <div className="d-none d-lg-block" style={{ height: "80px" }}></div>

      <div className="inner-banner inner-bg4">
        <div className="container">
          <div className="inner-title text-center">
            <h3>Sign In</h3>
            <ul>
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <i className="bx bxs-chevrons-right" />
              </li>
              <li>Sign In</li>
            </ul>
          </div>
        </div>
      </div>

      <section className="sign-in-area ptb-100">
        <div className="container">
          <div className="section-title text-center">
            <span>Sign In</span>
            <h2>Sign In to your account!</h2>
            <p>
              It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout.
            </p>
          </div>
          <div className="contact-wrap-form log-in-width">
            <form onSubmit={handleSubmit}>
              <div className="col-12">
                <div className="form-group">
                  <input
                    className="form-control"
                    type="text"
                    name="email"
                    placeholder="Username or Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="form-group">
                  <input
                    className="form-control"
                    type="password"
                    name="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="col-lg-6 col-sm-6 form-condition">
                <div className="agree-label">
                  <input type="checkbox" id="chb1" />
                  <label htmlFor="chb1">
                    Remember Me
                  </label>
                </div>
              </div>
              <div className="col-12 text-center">
                <button className="default-btn btn-two" type="submit">
                  Sign In Now
                </button>
              </div>
              {error && <p className="text-center text-danger">{error}</p>}
            </form>
          </div>
        </div>
      </section>
      
      <Footer />
    </div>
  );
}

export default Login;
