import React from 'react';
import './event.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';

const EventComponent = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Show 3 slides at a time
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024, // For larger screens
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 768, // For smaller screens
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div className="event-container">
      <section className="event-schedule">
        <h2>Meeting</h2>
        <p>Every  saturday</p>
        <div className="schedule-item">
          <span className="schedule-time">2:00 AM</span>
          <span className="schedule-activity">Meeting</span>
        </div>
        <div className="schedule-item">
          <span className="schedule-time">3:00 AM</span>
          <span className="schedule-activity">Coffee Break</span>
        </div>
        <h2>Tutor time</h2>
        <p>Every  Thursday</p>
        <div className="schedule-item">
          <span className="schedule-time">3 PM</span>
          <span className="schedule-activity">Tutorial for Children</span>
        </div>
      </section>


    </div>
  );
};

export default EventComponent;
