import React, { useState, useEffect } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import './index.css';
import Header from './component/Header';
import EventComponent from './component/event';
import Home from './component/Home';
import About from './component/About';
import Headslider from './component/headslider';
import Blog from './component/Blog';
import MobHeader from './component/Mobheader';
import Footer from './component/footer';
import Blogdetail from './component/blogdetails';
import Login from './component/signin';
import BlogPostForm from './component/Blogpost';
import Banner from './component/banner';
import File from './component/file';
import Members from './component/members';
import Service from './component/Service';
import Abouthome from './component/Abouthome';
import Preloader from './component/Preloader';
import ContactUs from './component/contact'; 
import BlogList from './component/bloglist';
import Dashboard from './component/dashboard';
import VideoSlider from './component/video';
import HeroSlider from './component/Heroslider';
import ImageTextSlider from './component/partern';
// Assuming you have a Preloader component


const router = createBrowserRouter([
  { path: '/', element: <Home /> },
 
  { path: '/header', element: <Header /> },
  {path: '/Heroslider', element: <HeroSlider />},
  { path: '/blog', element: <Blog /> },
  { path: '/mobheader', element: <MobHeader /> },
  { path: '/footer', element: <Footer /> },
  { path: '/blogdetails/:id', element: <Blogdetail /> },
  { path: '/signin', element: <Login /> },
  { path: '/blogpost', element: <BlogPostForm /> },
  { path: '/banner', element: <Banner /> },
  { path: '/file', element: <File /> },
  { path: '/members', element: <Members /> },
  { path: '/service', element: <Service /> },
  { path: '/abouthome', element: <Abouthome /> },
  { path: '/about', element: <About /> },
  { path: '/contact', element: <ContactUs /> },
  { path: '/bloglist', element: <BlogList /> },
  { path: '/dashboard', element: <Dashboard /> },
  { path: '/video', element: <VideoSlider /> },
  { path: '/headslider',element:<Headslider />},
  {path:'/event',element:<EventComponent/>},
  {path:'/partner',element:< ImageTextSlider/>},

]);

const App = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000); // 3 seconds

    return () => clearTimeout(timer); // Cleanup the timer on component unmount
  }, []);

  if (loading) {
    return <Preloader />;
  }

  return (
    <RouterProvider router={router} />
  );
};

ReactDOM.createRoot(document.getElementById('root')).render(
  <App />
);
