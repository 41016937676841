import React, { useState, useEffect } from 'react';
import Header from './Header';
import Footer from './footer';
import axios from 'axios';

const BlogList = () => {
    const [blogs, setBlogs] = useState([]);

    useEffect(() => {
        fetchBlogs();
    }, []);

    const fetchBlogs = async () => {
        try {
            const response = await axios.get('https://server.tinsaesms.com/blog');
            setBlogs(response.data);
        } catch (error) {
            console.error("There was an error fetching the blogs!", error);
        }
    };

    const deleteBlog = async (id) => {
        try {
            await axios.delete(`https://server.tinsaesms.com/blog/${id}`);
            setBlogs(blogs.filter(blog => blog._id !== id));
        } catch (error) {
            console.error("There was an error deleting the blog!", error);
        }
    };

    return (
        <div>
            <Header/>
            <div style={{ marginTop: "20px" }}></div>
            <div className="d-none d-lg-block" style={{ height: "80px" }}></div>
            <section className="banner-area inner-banner">
                <div>
                    <div className="inner-banner inner-bg4">
                        <div className="container">
                            <div className="inner-title text-center">
                                <h3>Your Latest Blogs</h3>
                                <ul>
                                    <li>
                                        <a href="/">Home</a>
                                    </li>
                                    <li>
                                        <i className="bx bxs-chevrons-right" />
                                    </li>
                                    <li>Blog</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="blog-area pt-100 pb-70">
                <div className="container">
                    <div className="section-title text-center">
                        <span>Blogs</span>
                        <h2>Your Latest Posts</h2>
                        <p>
                            you can delete your post from your blog!
                        </p>
                    </div>
                    <div className="row pt-45">
                        {/* Map over blogPosts array to render each blog post */}
                        {blogs.map(post => (
                            <div className="col-lg-4 col-md-6" key={post._id}>
                                <div className="blog-card">
                                    <div className="blog-img">
                                        <a href={`/blogdetails/${post._id}`}>
                                            <img src={`https://server.tinsaesms.com//${post.image}`} alt="Blog Images" />
                                        </a>
                                    </div>
                                    <div className="blog-content">
                                        <div className="blog-tag">
                                            <a href={`/blog/${post._id}`}><span>{post.author}</span></a>
                                        </div>
                                        <a href={`/blogdetails/${post._id}`}>
                                            <h3>{post.title}</h3>
                                        </a>
                                        <a href={`/blogdetails/${post._id}`} className="read-btn">Read More</a>
                                        <button style={{backgroundColor: 'red', color: 'white', border: 'none', padding: '5px 10px', borderRadius: '5px', cursor: 'pointer'}} onClick={() => deleteBlog(post._id)}>Delete</button>
``                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <Footer/>
            </div>
        </div>
    );
};

export default BlogList;
