import React from 'react'
import Header from './Header'
import Footer from './footer'

function Service() {
  return (
   
   
<div>
 <Header/>
 <div style={{ marginTop: "20px" }}></div>
      <div className="d-none d-lg-block" style={{ height: "80px" }}></div>
  <div className="inner-banner inner-bg1">
    <div className="container">
      <div className="inner-title text-center">
        <h3>Service</h3>
        <ul>
          <li>
            <a href="/">Home</a>
          </li>
          <li>
            <i className="bx bxs-chevrons-right" />
          </li>
          <li>Service </li>
        </ul>
      </div>
    </div>
  </div>
  <div className="about-area about-max pt-100 pb-70">
    <div className="container-fluid">
      <div className="row align-items-center justify-content-center">
        <div className="col-lg-5 col-xxl-6">
          <div className="about-img about-ml">
            <img src="assets/img/about/4.jpg" alt="About Images" />
          </div>
        </div>
        <div className="col-lg-7 col-xxl-6">
          <div className="about-content about-width">
            <span>Service</span>
            <h2>tinsae SMS emerges as a comprehensive, community-driven organization committed to addressing the multifaceted needs of single mothers</h2>
            <p>
            Effective parenting is essential for the development and well-being of children. Parenting classes cover topics such as child development, discipline strategies, communication skills, and building healthy family relationships.
Financial Literac Managing finances can be challenging. Financial literacy programs teach budgeting, saving, investing, and managing debt to help families achieve financial stability.
Career Development Continuing education and career development programs, such as vocational training, certifications, and degree programs, empower individuals to pursue higher-paying jobs and career advancement.
Life Skills  Workshops on topics such as time management, stress reduction, nutrition, and self-care are essential for overall well-being.
Tutoring and Academic SupportEnsuring children succeed in school is a priority. Organizations provide tutoring services, homework help, and educational enrichment programs for children of all ages.
            </p>
            <p>
              
            </p>
            <div className="about-btn">
              <a href="about" className="default-btn active">Know More</a>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  </div>
</div>

  )

}


export default Service